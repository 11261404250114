*{
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}

:root{
 --primary: #365899;
 --cards-color: #18689c;
 --nav-hover:rgb(126, 0, 0);
 --eventactive:#a91e10;
 --eventinactive: #d6d6d6;
 --lightbackg: #eeeeee;
 --pgrey:#565555;
 --bordernavitem:#4a69a3;
}


/* -----------------------------poster-advertisement------------------------------ */

/* Loading screen styles */
.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* semi-transparent white background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it appears above everything */
    opacity: 1;
    transition: opacity 0.5s ease-out;
  }
  
  .loading-screen img {
    width: 90%;
    max-width: 900px;
    margin-bottom: 20px;
  }
  
  /* Skip Button styling */
  .skip-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .skip-button:hover {
    background-color: #0056b3;
  }
  
  /* Blurring effect on main content */
  .main-container.blurred {
    filter: blur(5px); /* Adjust the blur intensity as needed */
    transition: filter 0.5s ease-out;
  }
  






/* ----------baner section----------------- */
.banner-h{
    height: 400px;
    /* margin-bottom: 20px; */
    
}    
 .background-h {
    height: 400px;
    display: flex;
}
.background-h img {
    height: 100%;
    width: 100%;
    display: flex;
    box-shadow: var(--lower-shadow);
}
    

/*------------ middle-section--------------- */

.middle-section{
    /* border: 2px solid red; */
    width: 95%;
    margin: auto;
    display: flex;


    margin-bottom: 40px;  /*-----testing----*/
}

/* --------------middle-left-------------- */

.middle-left{
    width: 65%;
}

.middle-left-content{
    width: 100%;
    /* border: 2px solid rebeccapurple; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    row-gap: 20px;
    padding: 0px 40px 0px 20px;
    padding-top: 25px;
    min-height: 750px;
    margin-bottom: 50px;
}

.middle-content-items{
   /* border: 2px solid red; */
   /* padding: 10px; */
   width: 200px;
   height: 210px;
   overflow: hidden; 
   position: relative;
   cursor: pointer;
}

.middle-content-items .inner-p {
    padding: 10px 0px;
    font-size: 13px;
    font-weight: 500;
    border-bottom: 2px dotted;
}

.middle-content-items .inner-p a{
    text-decoration: none;
    color: black;
}


.middle-content-items:hover .middle-hov-items {
    top:180px
}

.middle-hov-items{
   border-bottom: 3px solid var(--cards-color);
   position: absolute;
   content: "";
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   text-align: center;
   transition: all .4s;
   padding-bottom: 0px;
   background-color: white;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.middle-hov-items img{
   width: 100%;
   height: 70%;
   margin-bottom: 10px;
}

.middle-hov-items .outter-p{
    color: var(--cards-color);
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 10px;
}


/* ------------middle-down---------------- */

.middle-left-content-down{
    /* border: 2px solid red; */
    display: flex;
}

.left-mid-cont-down{
    /* width: 50%; */
    padding: 0px 5px;
}

.left-mid-cont-down p{
    font-weight: 600;
    font-size: 19px;
}

.right-mid-cont-down{
/* border: 2px solid pink; */
padding: 0px 20px
/* width: 06%; */
}

.uper-mid-cont-down{
   display: flex;
   justify-content: space-between;
   margin-bottom: 8px;
}
.uper-mid-cont-down p{
    font-weight: 600;
    font-size: 19px;
}
.uper-mid-cont-down button{
    background-color: var(--primary);
    color: white;
    padding: 5px;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    
}
.uper-mid-cont-down button:hover{
    background-color: white;
    color: var(--primary);
}

.lower-mid-cont-down p{
    font-size: 14px;
    color: var(--pgrey);
    text-align: justify;
    word-spacing: 5px;
    
}






/* --------------middle-right------------------ */

.middle-right{
    width: calc(100% - 65%);
    /* border: 2px solid blue; */
    background-color: var(--lightbackg);
    padding-top: 30px;
    padding-bottom: 30px;
    height: 1040px;
}


.m-r-upper{
    width: 97%;
    margin: auto;
    /* border: 2px solid red; */
    background-color: white;
}

.uper-row-btn{
display: flex;
}

.uper-row-btn button:nth-child(1){
    width: 60%;
}

.uper-row-btn button{
width: 40%;
padding:6px 10px;
font-weight: 500;
font-size: 16px;
border: none;
cursor: pointer;
}

.row-content-news, .row-content-events{
    /* border: 2px solid yellow; */
    height: 350px;
    overflow-x: auto;
    padding: 0px 10px;
    padding-top: 10px;
    scrollbar-width: thin;
}

.row-list {
    list-style:none;
}

.row-list a {
    text-decoration: none;
    display: flex;
    column-gap: 8px;
    color: var(--pgrey);
}

.row-list a .list-logo{
    width: 10px;
    padding-top: 0px;
}

.row-list a .list-logo img{
    width: 11px;
}

.row-list li{
    word-break:keep-all; 
    margin-bottom: 10px;
    width: 100%;
    font-size: 14px;
    word-spacing: 10px;
    text-align: justify;
}

.row-list li .new-note{
    width: 25px;
    margin-left: 2px;
    transform: rotate(-1deg);
}

.hidden{
    display: none;
}

.active{
    background-color: var(--eventactive);
    color: white;
}
.inactive{
    background-color: var(--eventinactive);
    color: black;
}


/* ---------donate btn middle---------- */

.middle-donate-btn{
    /* border: 2px solid ; */
    width: 97%;
    margin: auto;
}
.middle-donate-btn button{
    width: 100%;
    padding: 8px;
    font-size: 17px;
    background-color: var(--primary);
    color: white;
    border: 1px solid var(--primary);
    outline: none;
    border-radius: 5px;
    margin-top: 30px;
    cursor: pointer;
}

.middle-donate-btn button:hover{
    background-color: white;
    border: 1px solid var(--primary);
    color: var(--primary);
}

/* -----------social update--------------- */


.social-upt{
    /* border: 2px solid; */
    width: 97%;
    margin: auto;
}

.social-upt p{
    font-size: 22px;
    font-weight: 500;
    margin-top: 26px;
    margin-bottom: 5px;
}

.social-upt-btn button{
    width: 100%;
    padding: 8px;
    font-size: 15px;
    background-color: var(--primary);
    color: white;
    border: none;
    outline: none;
    /* border-radius: 5px; */
    cursor: pointer;
}

.social-upt-content{
    /* border: 2px solid red; */
    margin: auto;
    width: 90%;
    margin-top: 8px;
}



/* -----------hindi words---------------- */

.hindi-word{
    /* border: 2px solid ; */
    margin-top: 10px;
}

.hindi-content{
    width: 55%;
    min-height: 110px;
    margin: auto;
    /* border: 2px solid red; */
    background-color: white;
    padding-top: 10px;
}

.hindi-head{
    background-color: var(--primary);
    width: 90%;
    margin: auto;
    border-radius: 3px;
    color: white;
    text-align: center;
    padding: 5px;
}

.hindi-head p{
    font-size: 18px;
}

.hindi-content .p{
    text-align: center;
    margin-top: 10px;
}






/* -------------responsive------------------ */

@media screen and (max-width:1100px){
    .middle-section{
        flex-direction: column;
    }

    .middle-left {
        width: 100%;
        margin-bottom: 30px;
    }

    .middle-right {
        width: 100%;
    }

    .social-upt-content {
        display: flex;
        justify-content: center;
    }

    .middle-left-content{
        padding: 20px 0px 0px 0px;
    }

}

@media screen and (max-width:700px) {
    .middle-left-content{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width:600px) {
     .middle-left-content-down {
        flex-direction: column;
    }


}

@media screen and (max-width:500px) {
   .banner-h {
        height: 261px;
    }

    .background-h {
        height: 200px;
    }
}


@media screen and (max-width:450px) {
    .middle-left-content {
        grid-template-columns: 1fr;
    }
    
}

@media screen and (max-width:400px) {

    .background-h p {       
        width: 234px!important;
    }
    .banner-h {
        height: 261px;
    }
    .middle-content-items{
        width: 300px;
        height: 300px;
     }
}